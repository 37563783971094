<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>參數設定</b-breadcrumb-item>
            <b-breadcrumb-item active>系統資訊</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
        <h4 class="col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold">系統資訊</h4>
      </div>
      <b-card-text>
        <div class="row">
          <div class="col-12">
            <b-overlay :show="showLoading" rounded="sm">
              <b-table
                striped
                hover
                responsive
                :items="liffUrls"
                :fields="fields"
              >
                <template #cell(liff_url)="data">
                  <div class="row">
                    <div class="copy-panel col-2 cursor">
                      <div
                        id="copy-tooltip-target"
                        class="copy-button"
                        v-clipboard:copy="data.item.liff_url"
                        @click="clipboardSuccessHandler(data.item)"
                        v-b-tooltip.click="'已複製'"
                      >
                        <span
                          class="mdi mdi-link-variant"
                          aria-hidden="true"
                        ></span>
                      </div>
                    </div>
                    <div
                      class="col-2 mr-3"
                      v-clipboard:copy="data.item.liff_url"
                      @click="clipboardSuccessHandler(data.item)"
                      v-b-tooltip.click="'已複製'"
                    >
                      <a href="#">{{ data.item.liff_url }}</a>
                    </div>
                  </div>
                </template>
              </b-table>
            </b-overlay>
          </div>
        </div>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moduleApi from "../../../apis/module";
import * as consts from "@/consts";
import Vue from "vue";
import VueClipboard from "vue-clipboard2";

Vue.use(VueClipboard);

export default {
  data() {
    return {
      consts: consts,
      showLoading: false,
      modules: [],
      fields: [
        {
          key: "name",
          label: "功能",
        },
        {
          key: "liff_url",
          label: "網址",
        },
      ],
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
      dashboardModule: (state) => state.dashboardModule,
    }),
    liffUrls() {
      this.modules.forEach((module) => {
        if (
          module.module_code.includes("liff") &&
          module.module_code !== "liff_init"
        ) {
          module.liff_url =
            `https://liff.line.me/${this.organization.liff_id}` +
            module.module_code.replace("liff_", "/").replace("_", "-");
        }
        if (module.module_code === "member_center") {
          module.liff_url =
            `https://liff.line.me/${this.organization.liff_id}/` +
            module.module_code.replace("_", "-");
        }
        if (module.module_code === "point_center") {
          module.liff_url =
            `https://liff.line.me/${this.organization.liff_id}/` +
            module.module_code.replace("_", "/");
        }
        if (module.module_code === "member_edit") {
          module.liff_url = `https://liff.line.me/${this.organization.liff_id}/member-center/edit`;
        }
        if (module.module_code === "member_register") {
          module.liff_url = `https://liff.line.me/${this.organization.liff_id}/register`;
        }
        if (module.module_code === "worker_active") {
          module.liff_url = `https://liff.line.me/${this.organization.liff_id}/worker`;
        }
      });
      return this.modules.filter((module) => module.liff_url);
    },
  },
  watch: {},
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      await this.fetchModules();
    },
    async fetchModules() {
      try {
        this.showLoading = true;
        const { data } = await moduleApi.getLiffModuleList(
          this.organization.id
        );
        this.modules = data.data;
      } catch (error) {
        console.log("error", error);
      }
      this.showLoading = false;
    },
    clipboardSuccessHandler(item) {
      item.copied = true;
    },
  },
};
</script>

<style scoped>
.btn + .btn {
  margin-left: 0.5rem;
}

.copy-button {
  background: rgba(102, 153, 204, 0.03);
  border: 1px solid rgba(102, 153, 204, 0.6);
  color: #3973ac;
  padding: 4px 16px;
  border-radius: 10px;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  position: absolute;
  top: -7px;
}

.copy-panel {
  position: relative;
}

.cursor {
  cursor: pointer;
}
</style>
